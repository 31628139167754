export const forms = {
  success: {
    message: 'forms.success.message',
    actionMessage: 'forms.success.actionMessage',
  },
  errors: {
    fieldsRequired: 'forms.errors.fieldsRequired',
    fieldRequired: 'forms.errors.fieldRequired',
    invalidFormat: 'forms.errors.invalidFormat',
    maxAllowed: 'forms.errors.maxAllowed',
    negativeNotAllowed: 'forms.errors.negativeNotAllowed',
    phoneInvalid: 'forms.errors.phoneInvalid',
    termsAndConditions: 'forms.errors.termsAndConditions',
    privacyPolicy: 'forms.errors.privacyPolicy',
    reviewErrors: 'forms.errors.reviewErrors',
    invalidDate: 'forms.errors.invalidDate',
    genericError: 'forms.errors.genericError',
    numberValue: 'forms.errors.numberValue',
    notInteger: 'forms.errors.notInteger',
    notZero: 'forms.errors.notZero',
    maxPercentge: 'forms.errors.maxPercentge',
    remaining: 'forms.errors.remaining',
    wrongUrl: 'forms.errors.wrongUrl',
    minAge: 'forms.errors.minAge',
    maxAge: 'forms.errors.maxAge',
    minMonths: 'forms.errors.minMonths',
    minDate: 'forms.errors.minDate',
    maxDate: 'forms.errors.maxDate',
  },
  buttons: {
    accept: 'forms.buttons.accept',
    submit: 'forms.buttons.submit',
    cancel: 'forms.buttons.cancel',
    clean: 'forms.buttons.clean',
    clearFilters: 'forms.buttons.clearFilters',
    continue: 'forms.buttons.continue',
    back: 'forms.buttons.back',
    goBack: 'forms.buttons.goBack',
    delete: 'forms.buttons.delete',
    decline: 'forms.buttons.decline',
    add: 'forms.buttons.add',
    close: 'forms.buttons.close',
    open: 'forms.buttons.open',
    finish: 'forms.buttons.finish',
    search: 'forms.buttons.search',
    edit: 'forms.buttons.edit',
    editData: 'forms.buttons.editData',
    send: 'forms.buttons.send',
    save: 'forms.buttons.save',
    saveChanges: 'forms.buttons.saveChanges',
    saveAndClose: 'forms.buttons.saveAndClose',
    exit: 'forms.buttons.exit',
    discard: 'forms.buttons.discard',
    goHome: 'forms.buttons.goHome',
    backToResume: 'forms.buttons.backToResume',
    addToOrder: 'forms.buttons.addToOrder',
    downloadTemplate: 'forms.buttons.downloadTemplate',
    validateFile: 'forms.buttons.validateFile',
    continueToCheckout: 'forms.buttons.continueToCheckout',
    unsubscribe: 'forms.buttons.unsubscribe',
    repeatOrder: 'forms.buttons.repeatOrder',
    subscribe: 'forms.buttons.subscribe',
    downloadExcel: 'forms.buttons.downloadExcel',
    select: 'forms.buttons.select',
    change: 'forms.buttons.change',
    createNewCard: 'forms.buttons.createNewCard',
    configure: 'forms.buttons.configure',
    consult: 'forms.buttons.consult',
    backToEdit: 'forms.modals.backToEdit',
    leave: 'forms.modals.leave',
  },
  days: {
    day: 'forms.days.day',
    week: 'forms.days.week',
    month: 'forms.days.month',
    year: 'forms.days.year',
    monday: 'forms.days.monday',
    tuesday: 'forms.days.tuesday',
    wednesday: 'forms.days.wednesday',
    thursday: 'forms.days.thursday',
    friday: 'forms.days.friday',
    saturday: 'forms.days.saturday',
    sunday: 'forms.days.sunday',
  },
  months: {
    january: 'forms.months.january',
    february: 'forms.months.february',
    march: 'forms.months.march',
    april: 'forms.months.april',
    may: 'forms.months.may',
    june: 'forms.months.june',
    july: 'forms.months.july',
    august: 'forms.months.august',
    september: 'forms.months.september',
    october: 'forms.months.october',
    november: 'forms.months.november',
    december: 'forms.months.december',
  },
  contact: {
    name: 'forms.contact.name',
    secondName: 'forms.contact.secondName',
    lastName: 'forms.contact.lastName',
    firstLastName: 'forms.contact.firstLastName',
    secondLastName: 'forms.contact.secondLastName',
    phone: 'forms.contact.phone',
    contactPhone: 'forms.contact.contactPhone',
    phoneRecomendation: 'forms.contact.phoneRecomendation',
    fax: 'forms.contact.fax',
    email: 'forms.contact.email',
    corporativeEmail: 'forms.contact.corporativeEmail',
    corporativeEmailHelpText: 'forms.contact.corporativeEmailHelpText',
    documentTypeId: 'forms.contact.documentTypeId',
    documentTypeIdHelpText: 'forms.contact.documentTypeIdHelpText',
    document: 'forms.contact.document',
    birthdate: 'forms.contact.birthdate',
    dninie: 'forms.contact.dninie',
    passport: 'forms.contact.passport',
  },
  employee: {
    employeeNumber: 'forms.employee.employeeNumber',
    employeeNumber2: 'forms.employee.employeeNumber2',
    costCenter: 'forms.employee.costCenter',
  },
  address: {
    address: 'forms.address.address',
    streetName: 'forms.address.streetName',
    streetNumber: 'forms.address.streetNumber',
    aditionalInfo: 'forms.address.aditionalInfo',
    cp: 'forms.address.cp',
    zipCode: 'forms.address.zipCode',
    city: 'forms.address.city',
    province: 'forms.address.province',
    companyAddress: 'forms.address.companyAddress',
    personalAddress: 'forms.address.personalAddress',
    otherAddress: 'forms.address.otherAddress',
  },
  modals: {
    closeStayTitle: 'forms.modals.closeStayTitle',
    closeStaySubtitle: 'forms.modals.closeStaySubtitle',
    dataLoadedSuccessfully: 'forms.modals.dataLoadedSuccessfully',
    error: 'forms.modals.error',
    errorLines: 'forms.modals.errorLines',
    errorMessage: 'forms.modals.errorMessage',
    errorRecomendations: 'forms.modals.errorRecomendations',
    reviewedRecomendations: 'forms.modals.reviewedRecomendations',
    deleteTitle: 'forms.modals.deleteTitle',
    actionCantBeUndone: 'forms.modals.actionCantBeUndone',
  },
  placeholders: {
    name: 'forms.placeholders.name',
    firstLastname: 'forms.placeholders.firstLastname',
    secondLastname: 'forms.placeholders.secondLastname',
    corporativeEmail: 'forms.placeholders.corporativeEmail',
    contactPhone: 'forms.placeholders.contactPhone',
    document: 'forms.placeholders.document',
    select: 'forms.placeholders.select',
    rename: 'forms.placeholders.rename',
    employee: {
      employeeNumber: 'forms.placeholders.employee.employeeNumber',
      costCenter: 'forms.placeholders.employee.costCenter',
    },
    order: {
      amount: 'forms.placeholders.order.amount',
    },
  },
  info: {
    correctingDateIsNecessary: 'forms.info.correctingDateIsNecessary',
  },
  labels: {
    yes: 'forms.labels.yes',
    no: 'forms.labels.no',
    unassigned: 'forms.labels.unassigned',
  },
  table: {
    emptyMessage: 'forms.table.emptyMessage',
  },
  filter: {
    label: 'forms.filter.label',
  },
  selection: {
    usersSelected: 'forms.selection.usersSelected',
  },
  pagination: {
    next: 'forms.pagination.next',
    back: 'forms.pagination.back',
    of: 'forms.pagination.of',
  },
  tooltips: {
    proformaSigning: 'forms.tooltips.proformaSigning',
  },
}

export const modals = {
  leaveOrder: {
    title: 'modals.leaveOrder.title',
    description: 'modals.leaveOrder.description',
    button: {
      cancel: 'modals.leaveOrder.button.cancel',
      confirm: 'modals.leaveOrder.button.confirm',
    },
  },
}

export const languages = {
  es: 'languages.es',
  en: 'languages.en',
  ca: 'languages.ca',
}
