import { lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import Loader from '../components/Edenred/spinner/Loader'
import { DeliverySiteFormPage } from '../components/deliverySite/deliverySiteForm/DeliverySiteFormPage'
import { CardOrderFormPage } from '../components/cardOrder/cardOrderForm'
import { CardOrderCheckoutPage } from '../components/cardOrder/cardOrderCheckout/CardOrderCheckoutPage'
import { CardOrderCompletePage } from '../components/cardOrder/cardOrderComplete/CardOrderCompletePage'
import { CardOrderHomePage } from '../components/cardOrder/cardOrderHome/CardOrderHomePage'
import {
  navigationRoutes,
  flexNavigationRoutes,
  openNavigationRoutes,
} from '../../config/constants/navigationRoutes'
import { DuplicatesConfigurationPage } from '../components/cardOrder/duplicatesConfig/DuplicatesConfigurationPage'
import { MyOrdersPage } from '../components/cardOrder/myOrders/MyOrdersPage'
import { RechargesConfigurationPage } from '../components/cardOrder/rechargesConfig/RechargesConfigurationPage'
import { RechargeHomePage } from '../components/cardOrder/rechargeHome'
import { EditRechargesConfigurationPage } from '../components/cardOrder/rechargesConfig/EditRechargesConfigurationPage'
import { EditDuplicatesConfigurationPage } from '../components/cardOrder/duplicatesConfig/EditDuplicatesConfigurationPage'
import { OrderSummaryExcelPage } from '../components/cardOrder/cardOrderFormExcel/excelSummary'
import OrderExcelAlertsPage from '../components/cardOrder/cardOrderFormExcel/OrderExcelAlertsPage'
import {
  OrderExcelPage,
  OrderRechargeExcelPage,
} from '../components/cardOrder/cardOrderFormExcel/excelUpload'
import { OrderUnloadExcelPage } from '../components/cardOrder/cardOrderFormExcel/excelUpload/OrderUnloadExcelPage'
import { useOrderAssistant } from '../middleware/useOrderAssistant'
import { UnloadHomePage } from '../components/cardOrder/unloadHome'
import {
  EditUnloadsConfigurationPage,
  UnloadsConfigurationPage,
} from '../components/cardOrder/unloadsConfig'
import { OrderHomePage } from '../components/cardOrder/orderHome/OrderHomePage'
import MyClients from '../pages/MyClients/MyClients'
import { ClientHomePage } from '../components/client/home/ClientHomePage'
import { InvoicesPage } from '../components/invoices/InvoicesPage'
import { GeneralTermsPage } from '../components/generalTerms/GeneralTermsPage'
import { MyEmployees } from '../pages/MyEmployees'
import MyAccount from '../pages/MyAccount/MyAccount'
import { AccountSettingsPage } from '../components/account/settings/AccountSettingsPage'
import { AccountDeletedSuccessPage } from '../components/account/settings/AccountDeletedSuccessPage'
import { EmployeesPage } from '../components/employees'

import { FlexHomePage } from 'src/Flex/Shared/ui/FlexHomePage'
import { FlexEmployeesPage } from 'src/Flex/Employees/ui/employee-list'
import { FlexProductsPage } from 'src/Flex/Products/ui/product-list'
import { FlexCollectivesPage } from 'src/Flex/Collectives/ui/collective-list'
import { FlexCollectiveEditPage } from 'src/Flex/Collectives/ui/collective-detail'
import { FlexOrderImputationPage } from 'src/Flex/Orders/ui/order-imputation'

import { AccountNotificationsPage } from '../components/account/notifications/AccountNotificationsPage'
import { DeliverySitePageTR } from '../components/deliverySite'
import { HistoryPage } from '../components/history/HistoryPage'
import { SpendingRulesPage } from '../components/spendingRules/SpendingRulesPage'
import { AppLayout } from '../layout'
import { DuplicatedEmployeesPage } from '../components/cardOrder/duplicatedEmployees/DuplicatedEmployeesPage'
import { OrderValidationPage } from 'src/Flex/Orders/ui/order-validation'
import { SpendingRulesFormPage } from '../components/spendingRules/spendingRulesForm/SpendingRulesFormPage'
import { SpendingRulesEditPage } from '../components/spendingRules/spendingRulesEdit/SpendingRulesEditPage'
import { FlexOrdersAdherencePage } from 'src/Flex/Orders/ui/order-adherence'
import { FlexTemplatesListPage } from 'src/Flex/CommunicationsTemplates/ui/templates-list'
import { FlexTemplatesEditPage } from 'src/Flex/CommunicationsTemplates/ui/templates-detail'
import { RechargeEmployeesPage } from '../components/cardOrder/rechargeEmployees/RechargeEmployeesPage'
import { UnloadEmployeesPage } from '../components/cardOrder/unloadEmployees/UnloadEmployeesPage'
import { EmployeeExcelPage } from 'src/Flex/Employees/ui/employee-excel'
import { FlexPayrollsPage } from 'src/Flex/Payrolls/ui/payroll-list'
import { FlexPendingTasksPage } from 'src/Flex/TaskManager/ui/pending-tasks/FlexPendingTasksPage'
import { AccountUsersAndLicensesPage } from '../components/account/usersLicenses/AccountUsersAndLicensesPage'
import { NotFound } from '../pages/NotFound'
import { ProtectedView } from './ProtectedView'
import { actionTag, viewTag } from 'src/domain/enum/permissionTags'
import { useAuth } from '../context/auth/AuthProvider'
import { edenredProducts } from 'src/domain/enum'
import { EmployeeProfilePage } from '../components/employees'
import { DeliverySitePageTT } from '../components/deliverySite/DeliverySitePageTT'
import { TGDOrderPageTemplate } from '../components/TGDOrder/TGDOrderPageTemplate'
import { TGDOrderSummaryPage } from '../components/TGDOrder/TGDSummary/TGDOrderSummaryPage'
import { TGDOrderCheckoutPage } from '../components/TGDOrder/TGDOrderCheckout/TGDOrderCheckoutPage'
import {
  TGDOrderEditEmployeeAndChildPage,
  TGDOrderNewChildPage,
  TGDOrderNewEmployeeAndChildPage,
} from '../components/TGDOrder/TGDOrderForm'
import { TGDOrderEditChildPage } from '../components/TGDOrder/TGDOrderForm/TGDOrderEditChildPage'
import { TGDOrderCompletePage } from '../components/TGDOrder/TGDOrderComplete/TGDOrderCompletePage'
import { TGDOrderSelectSingleEmployeePage } from '../components/TGDOrder/TGDOrderSelectSingleEmployee/TGDOrderSelectSingleEmployeePage'
import { TGDOrderSelectMultipleEmployeesPage } from '../components/TGDOrder/TGDOrderSelectMultipleEmployees/TGDOrderSelectMultipleEmployeesPage'
import { TGDOrderConfigurationEmployeesPage } from '../components/TGDOrder/TGDOrderConfigurationEmployee/TGDOrderConfigurationEmployeesPage'
import { TGDOrderImportExcelPage } from '../components/TGDOrder/TGDOrderByExcel/importExcel/TGDOrderImportExcelPage'
import { TTOrderPageTemplate } from '../components/TTOrder/TTOrderPageTemplate'
import { TTOrderFormEditPage, TTOrderFormPage } from '../components/TTOrder/TTOrderForm'
import { TTOrderSelectMultipleEmployeesPage } from '../components/TTOrder/TTOrderSelectMultipleEmployees/TTOrderSelectMultipleEmployeesPage'
import { TTOrderConfigurationEmployeePage } from '../components/TTOrder/TTOrderConfigurationEmployee/TTOrderConfigurationEmployeePage'
import { TTRechargePageTemplate } from '../components/TTOrder/TTRechargePageTemplate'
import { TTRechargeSelectMultipleEmployeesPage } from '../components/TTOrder/TTRechargeSelectMultipleEmployees/TTRechargeSelectMultipleEmployeesPage'
import { TTOrderCompletePage } from '../components/TTOrder/TTOrderComplete/TTOrderCompletePage'
import { TTOrderCheckoutPage } from '../components/TTOrder/TTOrderCheckout/TTOrderCheckoutPage'
import { TTOrderSummaryPage } from '../components/TTOrder/TTOrderSummary/TTOrderSummaryPage'
import { TTRechargeConfigurationEmployeePage } from '../components/TTOrder/TTRechargeConfigurationEmployee/TTRechargeConfigurationEmployeePage'
import { TTRechargeSummaryPage } from '../components/TTOrder/TTRechargeSummary/TTRechargeSummaryPage'
import { TTUnloadPageTemplate } from '../components/TTOrder/TTUnloadPageTemplate'
import { TTUnloadConfigurationEmployeePage } from '../components/TTOrder/TTUnloadConfigurationEmployee/TTUnloadConfigurationEmployeePage'
import { TTUnloadSelectMultipleEmployeesPage } from '../components/TTOrder/TTUnloadSelectMultipleEmployees/TTUnloadSelectMultipleEmployeesPage'
import { TTUnloadSummaryPage } from '../components/TTOrder/TTUnloadSummary/TTUnloadSummaryPage'
import { TTOrderExcelPageTemplate } from '../components/TTOrder/TTOrderByExcel/TTOrderExcelPageTemplate'
import { TTOrderImportExcelPage } from '../components/TTOrder/TTOrderByExcel/TTOrderImportExcel/TTOrderImportExcelPage'
import { TTRechargeExcelPageTemplate } from '../components/TTOrder/TTOrderByExcel/TTRechargeExcelPageTemplate'
import { TTRechargeImportExcelPage } from '../components/TTOrder/TTOrderByExcel/TTOrderImportExcel/TTRechargeImportExcelPage'
import { TTUnloadExcelPageTemplate } from '../components/TTOrder/TTOrderByExcel/TTUnloadExcelPageTemplate'
import { TTUnloadImportExcelPage } from '../components/TTOrder/TTOrderByExcel/TTOrderImportExcel/TTUnloadImportExcelPage'
import { TTOrderExcelSummaryPage } from '../components/TTOrder/TTOrderByExcel/TTOrderExcelSummary/TTOrderExcelSummaryPage'
import { FlexEmployeeEditPage } from 'src/Flex/Employees/ui/employee-detail/FlexEmployeeEditPage'
import { TGDBasicOrderPageTemplate } from '../components/TGDBasic/TGDBasicOrderPageTemplate'
import { TGDBasicOrderNewEmployeeAndChildPage } from '../components/TGDBasic/TGDBasicOrderForm/TGDBasicOrderNewEmployeeAndChildPage'
import { TGDBasicOrderSummaryPage } from '../components/TGDBasic/TGDBasicOrderSummary/TGDBasicOrderSummaryPage'
import { TGDBasicOrderEditEmployeeAndChildPage } from '../components/TGDBasic/TGDBasicOrderForm/TGDBasicOrderEditEmployeeAndChildPage'
import { TGDBasicOrderCompletePage } from '../components/TGDBasic/TGDBasicOrderComplete/TGDBasicOrderCompletePage'
import { TGDBasicOrderCheckoutPage } from '../components/TGDBasic/TGDBasicOrderCheckout/TGDBasicOrderCheckoutPage'
import { RoleStandarizationPage } from '../components/roleStandarization/RoleStandarizationPage'
import { FlexPayrollCustomPage } from 'src/Flex/Payrolls/ui/payroll-custom/FlexPayrollCustomPage'
import { CheckStatusPage } from '../pages/CheckStatus/CheckStatusPage'

//lazy import main pages
const MyProducts = lazy(
  /* webpackChunkName: "MyProductsTest" */ () => import('../pages/MyProducts/MyProducts')
)

const InvoicesImport = lazy(
  /* webpackChunkName: "MyInvoicesSubmenu" */ () =>
    import('../pages/MyInvoices/MyInvoicesSubmenu')
)
const HistoryImport = lazy(
  /* webpackChunkName: "HistorySubmenu" */ () => import('../pages/History/HistorySubmenu')
)

const MyOrders = lazy(
  /* webpackChunkName: "MyOrdersTest" */ () => import('../pages/MyOrders/MyOrders')
)

const ClientRouter = () => {
  useOrderAssistant()
  const { userHomePage } = useAuth()

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route element={<AppLayout />}>
          <Route
            path={navigationRoutes.myProducts}
            element={
              <ProtectedView
                by={[
                  edenredProducts.ticketRestaurant.toString(),
                  edenredProducts.ticketTransporte.toString(),
                  edenredProducts.ticketGuarderia.toString(),
                ]}>
                <MyProducts />
              </ProtectedView>
            }>
            <Route
              path={navigationRoutes.myProducts}
              element={<Navigate to={navigationRoutes.deliverySiteTR} replace />}
            />

            <Route
              path={navigationRoutes.deliverySiteTR}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_DELIVERYSITE}>
                  <DeliverySitePageTR />
                </ProtectedView>
              }
            />
            <Route
              path={navigationRoutes.deliverySiteTT}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_DELIVERYSITE}>
                  <DeliverySitePageTT />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.deliverySiteNewTR}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_DELIVERYSITE}>
                  <DeliverySiteFormPage productCode={edenredProducts.ticketRestaurant} />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.deliverySiteNewTT}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_DELIVERYSITE}>
                  <DeliverySiteFormPage productCode={edenredProducts.ticketTransporte} />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.deliverySiteEditTR}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_DELIVERYSITE}>
                  <DeliverySiteFormPage productCode={edenredProducts.ticketRestaurant} />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.deliverySiteEditTT}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_DELIVERYSITE}>
                  <DeliverySiteFormPage productCode={edenredProducts.ticketTransporte} />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.spendingRules}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_SPENDINGRULES}>
                  <SpendingRulesPage />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.spendingRulesNew}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_SPENDINGRULES}>
                  <SpendingRulesFormPage />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.spendingRulesEditById}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_TR_SPENDINGRULES}>
                  <SpendingRulesEditPage />
                </ProtectedView>
              }
            />

            <Route
              path={navigationRoutes.generalTermsTR}
              element={
                <GeneralTermsPage productCode={edenredProducts.ticketRestaurant} />
              }
            />
            <Route
              path={navigationRoutes.generalTermsTT}
              element={
                <GeneralTermsPage productCode={edenredProducts.ticketTransporte} />
              }
            />
            <Route
              path={navigationRoutes.generalTermsTGD}
              element={<GeneralTermsPage productCode={edenredProducts.ticketGuarderia} />}
            />
          </Route>

          <Route path={navigationRoutes.account} element={<MyAccount />}>
            <Route
              path={navigationRoutes.account}
              element={<Navigate to={navigationRoutes.accountSettings} replace />}
            />
            <Route
              path={navigationRoutes.accountSettings}
              element={<AccountSettingsPage />}
            />
            <Route
              path={navigationRoutes.accountUsersAndLicenses}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_MYACCOUNT_USERACCESS}>
                  <AccountUsersAndLicensesPage />
                </ProtectedView>
              }
            />
            <Route
              path={navigationRoutes.accountNotification}
              element={<AccountNotificationsPage />}
            />
          </Route>

          <Route path={navigationRoutes.invoices} element={<InvoicesImport />}>
            <Route
              path={navigationRoutes.invoices}
              element={<Navigate to={navigationRoutes.invoicesHome} replace />}
            />
            <Route path={navigationRoutes.invoicesHome} element={<InvoicesPage />} />
          </Route>

          <Route path={navigationRoutes.historySpending} element={<HistoryImport />}>
            <Route
              path={navigationRoutes.historySpending}
              element={<Navigate to={navigationRoutes.historyTR} replace />}
            />
            <Route
              path={navigationRoutes.historyTR}
              element={<HistoryPage product={edenredProducts.ticketRestaurant} />}
            />
          </Route>

          <Route path={navigationRoutes.historyTGD} element={<HistoryImport />}>
            <Route
              path={navigationRoutes.historyTGD}
              element={<Navigate to={navigationRoutes.historyTGDHome} replace />}
            />
            <Route
              path={navigationRoutes.historyTGDHome}
              element={<HistoryPage product={edenredProducts.ticketGuarderia} />}
            />
          </Route>

          <Route path={navigationRoutes.historyTT} element={<HistoryImport />}>
            <Route
              path={navigationRoutes.historyTT}
              element={<Navigate to={navigationRoutes.historyTTHome} replace />}
            />
            <Route
              path={navigationRoutes.historyTTHome}
              element={<HistoryPage product={edenredProducts.ticketTransporte} />}
            />
          </Route>

          <Route path={navigationRoutes.clients} element={<MyClients />}>
            <Route
              path={navigationRoutes.clients}
              element={<Navigate to={navigationRoutes.clientsHome} replace />}
            />
            <Route
              path={navigationRoutes.clientsHome}
              element={
                <ProtectedView by={viewTag.VIEW_SUB_CLIENTS}>
                  <ClientHomePage />
                </ProtectedView>
              }
            />
          </Route>

          <Route
            path={navigationRoutes.myOrders}
            element={
              <ProtectedView by={viewTag.VIEW_SUB_MYORDERS}>
                <MyOrders />
              </ProtectedView>
            }>
            <Route
              path={navigationRoutes.myOrders}
              element={<Navigate to={navigationRoutes.orderHome} replace />}
            />
            <Route path={navigationRoutes.orderHome} element={<OrderHomePage />} />
            <Route path={navigationRoutes.myOrdersHistory} element={<MyOrdersPage />} />
            <Route
              path={navigationRoutes.cardOrderHome}
              element={<CardOrderHomePage />}
            />
            <Route path={navigationRoutes.cardOrderNew} element={<CardOrderFormPage />} />
            <Route
              path={navigationRoutes.cardOrderNewExcel}
              element={<OrderExcelPage />}
            />
            <Route
              path={navigationRoutes.cardOrderEdit}
              element={<CardOrderFormPage />}
            />
            <Route
              path={navigationRoutes.duplicatedCard}
              element={<DuplicatedEmployeesPage />}
            />
            <Route
              path={navigationRoutes.orderCheckout}
              element={<CardOrderCheckoutPage />}
            />
            <Route
              path={navigationRoutes.cardOrderCompleteError}
              element={<CardOrderCompletePage />}
            />
            <Route
              path={navigationRoutes.cardOrderCompleteSuccess}
              element={<CardOrderCompletePage />}
            />
            <Route
              path={navigationRoutes.rechargeCard}
              element={<RechargeEmployeesPage />}
            />
            <Route path={navigationRoutes.rechargeHome} element={<RechargeHomePage />} />
            <Route
              path={navigationRoutes.cardOrderRechargeExcel}
              element={<OrderRechargeExcelPage />}
            />
            <Route path={navigationRoutes.unloadHome} element={<UnloadHomePage />} />
            <Route path={navigationRoutes.unloadCard} element={<UnloadEmployeesPage />} />
            <Route
              path={navigationRoutes.cardOrderUnloadExcel}
              element={<OrderUnloadExcelPage />}
            />
            <Route
              path={navigationRoutes.cardOrderDuplicateConfiguration}
              element={<DuplicatesConfigurationPage />}
            />
            <Route
              path={navigationRoutes.cardOrderRechargeConfiguration}
              element={<RechargesConfigurationPage />}
            />
            <Route
              path={navigationRoutes.cardOrderUnloadConfiguration}
              element={<UnloadsConfigurationPage />}
            />
            <Route
              path={navigationRoutes.cardOrderRechargeConfigurationEdit}
              element={<EditRechargesConfigurationPage />}
            />
            <Route
              path={navigationRoutes.cardOrderDuplicateConfigurationEdit}
              element={<EditDuplicatesConfigurationPage />}
            />
            <Route
              path={navigationRoutes.cardOrderUnloadConfigurationEdit}
              element={<EditUnloadsConfigurationPage />}
            />
            <Route
              path={navigationRoutes.cardOrderExcelHome}
              element={<OrderSummaryExcelPage />}
            />
            <Route
              path={navigationRoutes.cardOrderExcelAlerts}
              element={<OrderExcelAlertsPage />}
            />
            {/*   /MisPedidos/TGD   */}
            <Route path={navigationRoutes.tgdOrder} element={<TGDOrderPageTemplate />}>
              <Route
                path={navigationRoutes.tgdOrder}
                element={<Navigate to={navigationRoutes.tgdOrderForm} replace />}
              />
              <Route
                path={navigationRoutes.tgdOrderForm}
                element={<TGDOrderNewEmployeeAndChildPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderFormEdit}
                element={<TGDOrderEditEmployeeAndChildPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderChildForm}
                element={<TGDOrderNewChildPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderChildFormEdit}
                element={<TGDOrderEditChildPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderSummary}
                element={<TGDOrderSummaryPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderCheckout}
                element={<TGDOrderCheckoutPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderCompleteError}
                element={<TGDOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.tgdOrderCompleteSuccess}
                element={<TGDOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.tgdOrderSelectSingleEmployee}
                element={<TGDOrderSelectSingleEmployeePage />}
              />
              <Route
                path={navigationRoutes.tgdOrderSelectMultipleEmployee}
                element={<TGDOrderSelectMultipleEmployeesPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderEmployeesConfiguration}
                element={<TGDOrderConfigurationEmployeesPage />}
              />
              <Route
                path={navigationRoutes.tgdOrderExcel}
                element={<TGDOrderImportExcelPage />}
              />
            </Route>
            {/*   /MisPedidos/TGDBasic   */}
            <Route
              path={navigationRoutes.tgdBasicOrder}
              element={<TGDBasicOrderPageTemplate />}>
              <Route
                path={navigationRoutes.tgdBasicOrder}
                element={<Navigate to={navigationRoutes.tgdBasicOrderForm} replace />}
              />
              <Route
                path={navigationRoutes.tgdBasicOrderForm}
                element={<TGDBasicOrderNewEmployeeAndChildPage />}
              />
              <Route
                path={navigationRoutes.tgdBasicOrderFormEdit}
                element={<TGDBasicOrderEditEmployeeAndChildPage />}
              />
              <Route
                path={navigationRoutes.tgdBasicOrderSummary}
                element={<TGDBasicOrderSummaryPage />}
              />
              <Route
                path={navigationRoutes.tgdBasicOrderCompleteError}
                element={<TGDBasicOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.tgdBasicOrderCompleteSuccess}
                element={<TGDBasicOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.tgdBasicOrderCheckout}
                element={<TGDBasicOrderCheckoutPage />}
              />
            </Route>
            {/*   /MisPedidos/TT   */}
            <Route path={navigationRoutes.ttOrder} element={<TTOrderPageTemplate />}>
              <Route
                path={navigationRoutes.ttOrder}
                element={<Navigate to={navigationRoutes.ttOrderForm} replace />}
              />
              <Route path={navigationRoutes.ttOrderForm} element={<TTOrderFormPage />} />
              <Route
                path={navigationRoutes.ttOrderFormEdit}
                element={<TTOrderFormEditPage />}
              />
              <Route
                path={navigationRoutes.ttOrderSummary}
                element={<TTOrderSummaryPage />}
              />
              <Route
                path={navigationRoutes.ttOrderSelectMultipleEmployee}
                element={<TTOrderSelectMultipleEmployeesPage />}
              />
              <Route
                path={navigationRoutes.ttOrderEmployeesConfiguration}
                element={<TTOrderConfigurationEmployeePage />}
              />
              <Route
                path={navigationRoutes.ttOrderEmployeesConfigurationEdit}
                element={<TTOrderConfigurationEmployeePage />}
              />
              <Route
                path={navigationRoutes.ttOrderCompleteSuccess}
                element={<TTOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.ttOrderCompleteError}
                element={<TTOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.ttOrderCheckout}
                element={<TTOrderCheckoutPage />}
              />
            </Route>
            {/*   /MisPedidos/TT/Recarga   */}
            <Route
              path={navigationRoutes.ttRecharge}
              element={<TTRechargePageTemplate />}>
              <Route
                path={navigationRoutes.ttRecharge}
                element={
                  <Navigate
                    to={navigationRoutes.ttRechargeSelectMultipleEmployee}
                    replace
                  />
                }
              />
              <Route
                path={navigationRoutes.ttRechargeSelectMultipleEmployee}
                element={<TTRechargeSelectMultipleEmployeesPage />}
              />
              <Route
                path={navigationRoutes.ttRechargeEmployeesConfiguration}
                element={<TTRechargeConfigurationEmployeePage />}
              />
              <Route
                path={navigationRoutes.ttRechargeEmployeesConfigurationEdit}
                element={<TTRechargeConfigurationEmployeePage />}
              />
              <Route
                path={navigationRoutes.ttRechargeSummary}
                element={<TTRechargeSummaryPage />}
              />
              <Route
                path={navigationRoutes.ttRechargeCheckout}
                element={<TTOrderCheckoutPage />}
              />
              <Route
                path={navigationRoutes.ttRechargeCompleteSuccess}
                element={<TTOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.ttRechargeCompleteError}
                element={<TTOrderCompletePage />}
              />
            </Route>
            {/*   /MisPedidos/TT/Descarga   */}
            <Route path={navigationRoutes.ttUnload} element={<TTUnloadPageTemplate />}>
              <Route
                path={navigationRoutes.ttUnload}
                element={
                  <Navigate
                    to={navigationRoutes.ttUnloadSelectMultipleEmployee}
                    replace
                  />
                }
              />
              <Route
                path={navigationRoutes.ttUnloadSelectMultipleEmployee}
                element={<TTUnloadSelectMultipleEmployeesPage />}
              />
              <Route
                path={navigationRoutes.ttUnloadEmployeesConfiguration}
                element={<TTUnloadConfigurationEmployeePage />}
              />
              <Route
                path={navigationRoutes.ttUnloadEmployeesConfigurationEdit}
                element={<TTUnloadConfigurationEmployeePage />}
              />
              <Route
                path={navigationRoutes.ttUnloadSummary}
                element={<TTUnloadSummaryPage />}
              />
              <Route
                path={navigationRoutes.ttUnloadCheckout}
                element={<TTOrderCheckoutPage />}
              />
              <Route
                path={navigationRoutes.ttUnloadCompleteSuccess}
                element={<TTOrderCompletePage />}
              />
              <Route
                path={navigationRoutes.ttUnloadCompleteError}
                element={<TTOrderCompletePage />}
              />
            </Route>

            {/*   /MisPedidos/TT/Excel   */}
            <Route
              path={navigationRoutes.ttOrderExcel}
              element={<TTOrderExcelPageTemplate />}>
              <Route
                path={navigationRoutes.ttOrderExcel}
                element={<Navigate to={navigationRoutes.ttNewOrderExcel} replace />}
              />
              <Route
                path={navigationRoutes.ttOrderExcelSummary}
                element={<TTOrderExcelSummaryPage />}
              />
              <Route
                path={navigationRoutes.ttOrderExcelCheckout}
                element={<TTOrderCheckoutPage />}
              />
            </Route>

            {/*   /MisPedidos/TT/Excel/Nuevo   */}
            <Route
              path={navigationRoutes.ttNewOrderExcel}
              element={<TTOrderExcelPageTemplate />}>
              <Route
                path={navigationRoutes.ttNewOrderExcel}
                element={<Navigate to={navigationRoutes.ttNewOrderImportExcel} replace />}
              />
              <Route
                path={navigationRoutes.ttNewOrderImportExcel}
                element={<TTOrderImportExcelPage />}
              />
            </Route>

            {/*   /MisPedidos/TT/Excel/Recarga   */}
            <Route
              path={navigationRoutes.ttRechargeExcel}
              element={<TTRechargeExcelPageTemplate />}>
              <Route
                path={navigationRoutes.ttRechargeExcel}
                element={<Navigate to={navigationRoutes.ttRechargeImportExcel} replace />}
              />
              <Route
                path={navigationRoutes.ttRechargeImportExcel}
                element={<TTRechargeImportExcelPage />}
              />
              <Route
                path={navigationRoutes.ttRechargeSummaryExcel}
                element={<TTOrderExcelSummaryPage />}
              />
              <Route
                path={navigationRoutes.ttRechargeExcelCheckout}
                element={<TTOrderCheckoutPage />}
              />
            </Route>

            {/*   /MisPedidos/TT/Excel/Descarga   */}
            <Route
              path={navigationRoutes.ttUnloadExcel}
              element={<TTUnloadExcelPageTemplate />}>
              <Route
                path={navigationRoutes.ttUnloadExcel}
                element={<Navigate to={navigationRoutes.ttUnloadImportExcel} replace />}
              />
              <Route
                path={navigationRoutes.ttUnloadImportExcel}
                element={<TTUnloadImportExcelPage />}
              />
              <Route
                path={navigationRoutes.ttUnloadSummaryExcel}
                element={<TTOrderExcelSummaryPage />}
              />
              <Route
                path={navigationRoutes.ttUnloadExcelCheckout}
                element={<TTOrderCheckoutPage />}
              />
            </Route>
          </Route>

          <Route
            path={navigationRoutes.employees}
            element={
              <ProtectedView by={viewTag.VIEW_SUB_EMPLOYEES}>
                <MyEmployees />
              </ProtectedView>
            }>
            <Route
              path={navigationRoutes.employees}
              element={<Navigate to={navigationRoutes.employeesHome} replace />}
            />
            <Route path={navigationRoutes.employeesHome} element={<EmployeesPage />} />
            <Route
              path={navigationRoutes.employeeProfile}
              element={<EmployeeProfilePage />}
            />
          </Route>

          <Route
            path={flexNavigationRoutes.flex}
            element={
              <ProtectedView by={viewTag.VIEW_FL_HOME}>
                <FlexHomePage />
              </ProtectedView>
            }>
            <Route
              path={flexNavigationRoutes.flex}
              element={
                <ProtectedView by={viewTag.VIEW_FL_HOME}>
                  <FlexPendingTasksPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexProducts}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexProductsPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexEmployees}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexEmployeesPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexEmployeesExcel}
              element={
                <ProtectedView
                  by={[
                    viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
                    actionTag.ACTION_FL_EMPLOYEES_ADDANDEDIT,
                  ]}>
                  <EmployeeExcelPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexEmployeesCreate}
              element={
                <ProtectedView
                  by={[
                    viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
                    actionTag.ACTION_FL_EMPLOYEES_ADDANDEDIT,
                  ]}>
                  <FlexEmployeeEditPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexEmployeesEdit}
              element={
                <ProtectedView
                  by={[
                    viewTag.VIEW_FL_RETRIBUTIONPLATFORM,
                    actionTag.ACTION_FL_EMPLOYEES_LIST_EDIT,
                  ]}>
                  <FlexEmployeeEditPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexCollectives}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexCollectivesPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexCollectivesCreate}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexCollectiveEditPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexCollectivesEdit}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexCollectiveEditPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexOrdersValidate}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <OrderValidationPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexProductsAdherence}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexOrdersAdherencePage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexProductsImputation}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexOrderImputationPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexCommunicationsTemplates}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexTemplatesListPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexCommunicationsTemplatesCreate}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexTemplatesEditPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexCommunicationsTemplatesEdit}
              element={
                <ProtectedView by={viewTag.VIEW_FL_RETRIBUTIONPLATFORM}>
                  <FlexTemplatesEditPage />
                </ProtectedView>
              }
            />
            <Route
              path={flexNavigationRoutes.flexPayrolls}
              element={<FlexPayrollsPage />}
            />
            <Route
              path={flexNavigationRoutes.flexPayrollsDnD}
              element={<FlexPayrollCustomPage />}
            />
          </Route>

          <Route path={navigationRoutes.notFound} element={<NotFound />} />
        </Route>

        <Route
          path={navigationRoutes.roleStandarizationAcceptance}
          element={<RoleStandarizationPage />}
        />

        <Route
          path={navigationRoutes.accountDeleted}
          element={<AccountDeletedSuccessPage />}
        />

        <Route
          path={openNavigationRoutes.logaltyCheckStatus}
          element={<CheckStatusPage />}
        />

        {/* Redirect to home according to permissions whe root '/' */}
        <Route path="/" element={<Navigate to={userHomePage} replace />} />

        <Route path="*" element={<Navigate to={navigationRoutes.notFound} replace />} />
      </Routes>
    </Suspense>
  )
}

export default ClientRouter
